<template>
  <styleLoader />
  <a-config-provider :locale="localeAntd">
    <router-view />
  </a-config-provider>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import store from 'store'
import qs from 'qs'
import StyleLoader from '@/styleLoader'

import russian from '@/locales/ru-RU'
import english from '@/locales/en-US'

export default {
  name: 'App',
  components: { StyleLoader },
  setup() {
    const locales = {
      'ru-RU': russian,
      'en-US': english,
    }
    const storeUse = useStore()
    const route = useRoute()
    const router = useRouter()
    const settingsLocale = computed(() => storeUse.state.settings.locale)
    const localeAntd = ref(locales[settingsLocale.value].localeAntd)
    const logo = computed(() => storeUse.getters.settings.logo)
    const routeTitle = computed(() => route.meta.title)
    const currentRoute = computed(() => route)
    const authorized = computed(() => storeUse.getters['user/user'].authorized)

    // watch page title change
    watch(
      [logo, routeTitle],
      ([logo, routeTitle]) => (document.title = `${logo} | ${routeTitle}` || `${logo}`),
    )

    // initial auth check
    onMounted(() => {
      if (store.get('accessToken'))
        storeUse.dispatch('user/LOAD_CURRENT_ACCOUNT')
    })

    // redirect if authorized and current page is login
    /*
    watch(authorized, authorized => {
      if (authorized) {
        const query = qs.parse(currentRoute.value.fullPath.split('?')[1], {
          ignoreQueryPrefix: true,
        })
        router.push(query.redirect || '/')
      }
    })
    */
    watch(authorized, authorized => {
      if (authorized) {
        const authRequired = currentRoute.value?.meta?.authRequired ?? null
        if (authRequired === false)
          return false;

        let url = '/'

        const fullPath = currentRoute.value.fullPath.split('?')

        if (fullPath[2]) {
          url = `${fullPath[1]}?${fullPath[2]}`
        } else if (fullPath[1]) {
          url = fullPath[1]
        }

        const path = qs.parse(url, {
          ignoreQueryPrefix: true
        })

        const redirect = path.redirect ?? '/'

        if (redirect)
          router.push(redirect)
        else
          router.push('/')
      }
    })

    return {
      localeAntd,
    }
  },
}
</script>
<style>
.ant-modal-close-icon svg{
  display: none;
}
.ant-modal-close-icon{
  background: url('/resources/images/close.svg');
  background-size: cover;
  width: 0.75em;
  height: 0.75em;
  display: block;
  margin: 20px 25px;
}
</style>
