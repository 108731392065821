<template>
  <div
    :class="{
      [$style.footer]: true,
      [$style.footerFullWidth]: !settings.isContentMaxWidth,
    }"
  >
    <div :class="$style.inner">
      <div class="row">
        <div class="col-md-8">
          <p>
            <strong>Visual Builder is the best solution for your next big app!</strong>
          </p>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industrys standard dummy text ever since the 1500s, when an unknown printer
            took a galley of type and scrambled it to make a type specimen book.
          </p>
          <p class="mb-md-0 mb-4">
            Copyright © {{ new Date().getFullYear() }}
            |
            <a href="https://rhmt.uz" target="_blank" rel="noopener noreferrer">
              О проекте
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)

    return {
      settings,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
