<template>
  <div :class="$style.chat">
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
  },
  computed: mapState(['settings']),
  methods: {
    toggleSupportChat: function() {
      const setting = 'isSupportChatOpen'
      const value = !this.settings[setting]
      this.$store.commit('CHANGE_SETTING', { setting, value })
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
