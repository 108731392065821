<template>
  <a-menu-item
    :key="menuInfo.key"
    v-bind="$props"
    :disabled="menuInfo.disabled"
    :id="menuInfo.key"
    :class="{
      'ant-menu-item-selected': route.name === menuInfo?.name && route.name !== 'home'
    }"
  >
    <router-link
      v-if="menuInfo.url"
      :to="menuInfo.url"
      :class="{
        'router-link-active router-link-exact-active': route.name === menuInfo?.name && route.name !== 'home'
      }"
    >
      <span :class="styles.title">{{ menuInfo.title }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{ menuInfo.count }}</span>
<!--      <i v-if="menuInfo.svg === true" v-html="menuInfo.icon" class="svg-icon"></i>-->
      <i
        :id="menuInfo.key + '_icon'"
        v-if="menuInfo.icon"
        :class="[styles.icon, menuInfo.icon]"
        class="float-right ml-3"
        :style="{ fontWeight: menuInfo['weight'] ? menuInfo['weight'] : 'normal'}"
      >
        <span v-if="menuInfo.key === '__review_all'">{{ reviews?.all }}</span>
        <span v-if="menuInfo.key === '__review_new'">{{ reviews?.new }}</span>
        <span v-if="menuInfo.key === '__review_progress'">{{ reviews?.progress }}</span>
        <span v-if="menuInfo.key === '__review_closed'">{{ reviews?.closed }}</span>
      </i>
      <span v-if="menuInfo.icon === 'pos_system'">
        <DisconnectOutlined class="custom-ant-icon" :class="[styles.icon]" />
      </span>
    </router-link>
    <span v-if="!menuInfo.url" :to="menuInfo.url">
      <span :class="styles.title">{{ menuInfo.title }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{ menuInfo.count }}</span>
      <i v-if="menuInfo.icon" :class="[styles.icon, menuInfo.icon]" />
    </span>
  </a-menu-item>
</template>

<script>
import { Menu } from 'ant-design-vue'
import {DisconnectOutlined} from '@ant-design/icons-vue';
import {useRoute} from "vue-router";

export default {
  name: 'SubMenu',
  components: {DisconnectOutlined},
  props: {
    ...Menu.Item.props,
    menuInfo: {
      type: Object,
      default: () => {},
    },
    styles: {
      type: Object,
      default: () => {},
    },
    reviews: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      route: useRoute()
    }
  },
}
</script>
<style lang="scss">
[data-vb-theme="default"] .ant-drawer-open .ant-menu-item a .svg-icon svg{
  fill: #a1a1c2;
}
//[data-vb-theme="default"] .ant-layout-sider-collapsed .ant-menu-item a .svg-icon svg{
//  fill: #595c97;
//}
.svg-icon {
  height: 20px;
  text-align: center;
  position: absolute;
  right: 14px;
  width: 20px;
  top: 11px;
  margin: 0 !important;
  line-height: 1 !important;
  color: #a1a1c2;
  font-size: 1.13rem;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  svg {
    width: 80%;
    height: 80%;
    fill: #a1a1c2;
    transition: fill 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
.index_menu_1DFTu .svg-icon svg {
  fill: #a1a1c2;
}
.ant-layout-sider-collapsed .router-link-exact-active .svg-icon svg {
  fill: #fff !important;
}
[data-vb-theme="default"] .ant-menu-item a:hover .svg-icon svg{
  fill: #4b7cf3;
}
.index_menu_1DFTu .index_navigation_3fab1 .ant-menu-submenu-active .svg-icon svg {
  fill: var(--vb-color-primary);
  transition: fill 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#app .ant-menu-light .ant-menu-item-selected a,
#app .ant-menu-light .ant-menu-item-selected .svg-icon svg {
  fill: #fff !important;
}
.index_menu_1DFTu.ant-layout-sider-collapsed .ant-menu-item .svg-icon,
.index_menu_1DFTu.ant-layout-sider-collapsed .ant-menu > .ant-menu-submenu > .ant-menu-submenu-title .svg-icon {
  right: 1.2rem;
}
.index_menu_1DFTu .index_navigation_3fab1 .ant-menu-submenu-selected .svg-icon svg {
  fill: var(--vb-color-primary);
}
.ant-tooltip.ant-menu-inline-collapsed-tooltip a {
  color: #fff !important;
}
.ant-menu-vertical #__review_all_icon.index_icon_2Pfxo,
.ant-menu-vertical #__review_new_icon.index_icon_2Pfxo,
.ant-menu-vertical #__review_progress_icon.index_icon_2Pfxo,
.ant-menu-vertical #__review_closed_icon.index_icon_2Pfxo{
  line-height: 40px !important;
}
#__review_all_icon,
#__review_new_icon,
#__review_progress_icon,
#__review_closed_icon{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  line-height: 20px !important;
  font-size: 12px;
  font-weight: bold;
  width: 50px;
  text-align: right;
}
#__review_all_icon:before,
#__review_new_icon:before,
#__review_progress_icon:before,
#__review_closed_icon:before{
  content: ""
}
.ant-menu-vertical .index_icon_2Pfxo.float-right {
  line-height: 40px;
}
</style>
