import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'
import Maska from 'maska'
import './registerServiceWorker'
import router from './router'
import CKEditor from '@ckeditor/ckeditor5-vue'
import store from './store'
import './index.css'

createApp(App)
  .use(store)
  .use(router)
  .use(Maska)
  .use(Antd)
  .use(CKEditor)
  .mount('#app')
