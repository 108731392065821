<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <a-avatar shape="square" size="large" :class="$style.avatar">
        <template #icon><UserOutlined /></template>
      </a-avatar>
    </div>
    <template #overlay>
      <a-menu>
        <a-menu-item>
          <div>
            <strong>Привет, {{ user.username || 'Anonymous' }}</strong>
          </div>
          <div>
            <strong class="mr-1">Роль:</strong>
            {{ user.role || '—' }}
          </div>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item>
          <div>
            <strong class="mr-1">Емайл:</strong>
            {{ user.email || '—' }}
          </div>
        </a-menu-item>
        <a-menu-divider />
<!--        <a-menu-item>-->
<!--          <a href="javascript: void(0);">-->
<!--            <i class="fe fe-user mr-2" />-->
<!--            {{ $t('topBar.profileMenu.editProfile') }}-->
<!--          </a>-->
<!--        </a-menu-item>-->
<!--        <a-menu-divider />-->
        <a-menu-item style="padding: 0">
          <a href="javascript: void(0);" class="d-block" @click="logout" style="padding: 5px 12px">
            <i class="fe fe-log-out mr-2" />
            Выйти
          </a>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { UserOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  components: {
    UserOutlined,
  },
  setup() {
    const store = useStore()
    const user = computed(() => store.getters['user/user'])

    const logout = () => {
      store.dispatch('user/LOGOUT')
    }

    return {
      user,
      logout,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
