import {onMounted, onUnmounted, computed, ref} from "vue";
export function useScreenSize() {
  const windowWidth = ref(window.innerWidth);

  const onWidthChange = () => windowWidth.value = window.innerWidth;
  onMounted(() => window.addEventListener('resize', onWidthChange));
  onUnmounted(() => window.removeEventListener('resize', onWidthChange));

  const isMobile = computed(() => windowWidth.value < 768);

  return isMobile;
}
