export default {
  namespaced: true,
  state: {
    cart: JSON.parse(localStorage.getItem('cart')) || [],
  },
  mutations: {
    CART_PUSH(state, payload) {
      const item = state.cart.find((c) => {
        return c.item.id === payload.item.id;
      });
      if (item) {
        item.count += payload.count;
      } else {
        state.cart.push({
          item: payload.item,
          count: payload.count,
        });
      }
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    CART_REMOVE(state, payload) {
      Array.prototype.removeByValue = function(payload) {
        for (let i = 0; i < this.length; i++) {
          if (this[i].item.id === payload.item.id) {
            if (payload.all) {
              this[i].count = 0;
            } else {
              this[i].count--;
            }
            if (!this[i].count) {
              this.splice(i, 1);
            }
          }
        }
      };
      localStorage.setItem("cart", JSON.stringify(state.cart));
      state.cart.removeByValue(payload);
      return this;
    },
    CART_CLEAR(state) {
      state.cart = [];
      // console.log(state.cart);
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
  },
  actions: {
    addToCart({ commit }, payload) {
      commit("CART_PUSH", payload);
    },
    removeFromCart({commit}, payload) {
      commit("CART_REMOVE", payload);
    },
    clearCart({commit}) {
      commit("CART_CLEAR");
    },
  },
  getters: {
    getCart: state => state.cart,
  },
};
