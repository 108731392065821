import store from 'store'

const STORED_FILTERS = storedFilters => {
  const filters = {}
  Object.keys(storedFilters).forEach(key => {
    const item = store.get(`app.filters.${key}`)
    filters[key] = typeof item !== 'undefined' ? item : storedFilters[key]
  })
  return filters
}

export default {
  state: {
    ...STORED_FILTERS({
      // VB:REPLACE-START:FILTERS
      // VB:REPLACE-END:FILTERS
    }),
  },
  mutations: {
    CHANGE_FILTER(state, payload) {
      store.set(`app.filters.${payload.page}`, payload.value)
      state[payload.page] = payload.value
    },
    // CHANGE_FILTER_BULK(state, payload) {
    //   const filters = {}
    //   Object.keys(payload).forEach(key => {
    //     store.set(`app.filters.${key}`, payload[key])
    //     filters[key] = payload[key]
    //     state[key] = payload[key]
    //   })
    // },
  },
  actions: {},
  getters: {
    filters: state => state,
  },
}
