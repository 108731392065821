import { createStore } from 'vuex'
import user from './user'
import settings from './settings'
import cashier from './cashier'
import filters from './filter'

export default createStore({
  modules: {
    user,
    settings,
    cashier,
    filters
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
})
