<template>
  <div :class="$style.footer">
    <div :class="$style.footerInner">
      <p class="mb-md-0 mb-4">
        Copyright © {{ new Date().getFullYear() }}
        |
        <a href="https://rhmt.uz" target="_blank" rel="noopener noreferrer">
          О проекте
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)

    return {
      settings,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
