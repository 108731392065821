<template>
  <a-sub-menu :key="menuInfo['key']" v-bind="$props">
    <template #title>
      <span>
        <span :class="styles['title']">{{ menuInfo['title'] }}</span>
        <span v-if="menuInfo['count']" class="badge badge-success ml-2">{{ menuInfo['count'] }}</span>
        <i
          v-if="menuInfo['icon']"
          :class="[styles['icon'], menuInfo['icon']]"
          :style="{ fontWeight: menuInfo['weight'] ? menuInfo['weight'] : 'normal'}"
        />
<!--        <span v-if="menuInfo.icon === 'establish'">-->
<!--          <ApartmentOutlined class="custom-ant-icon" style="line-height: 16px" />-->
<!--        </span>-->
      </span>
    </template>
    <template v-for="item in menuInfo['children']">
      <item
        v-if="!item.children && !item['divider']"
        :key="item.key"
        :menu-info="item"
        :styles="styles"
        :reviews="reviews"
      />
      <sub-menu v-if="item.children" :key="item.key" :menu-info="item" :styles="styles" />
    </template>
  </a-sub-menu>
</template>

<script>
import { Menu } from 'ant-design-vue'
import Item from './item'
// import {
//   ApartmentOutlined
// } from '@ant-design/icons-vue';

export default {
  name: 'SubMenu',
  components: {
    Item,
    // ApartmentOutlined
  },
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    menuInfo: {
      type: Object,
      default: () => {},
    },
    styles: {
      type: Object,
      default: () => {},
    },
    reviews: {
      type: Array,
      default: () => []
    }
  },
}
</script>

<style>

.custom-ant-icon {
  color: #a1a1c2; right: 18.5px; position: absolute; top:11px; margin: 0
}
.ant-drawer-open .router-link-exact-active,
.ant-layout-sider-collapsed .router-link-exact-active {
  span {
    color: #fff;
  }
  i {
    color: #fff !important;
  }
  .custom-ant-icon {
    svg {
      fill: white !important;
      stroke: white !important;
    }
  }
}
</style>
