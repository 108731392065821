export const getMenuData = [
  // VB:REPLACE-START:MENU-CONFIG
  {
    title: 'Админ панель',
    key: '__dashboard',
    url: '/dashboard',
    name: 'dashboard',
    icon: 'lnr lnr-chart-bars',
    roles: ['admin'],
  },
  // {
  //   title: 'Мониторинг POS',
  //   key: '__pos-system_types',
  //   url: '/pos-system',
  //   icon: 'pos_system',
  //   roles: ['admin'],
  // },
  {
    title: 'Заведения',
    key: '__establish',
    icon: 'icmn-tree',
    roles: ['admin', 'brandmanager'],
    children: [
      {
        title: 'Бренды',
        key: '__brand',
        url: '/brand',
        icon: 'fa fa-copyright',
      },
      {
        title: 'Филиалы',
        key: '__store',
        url: '/store',
        icon: 'fe fe-map-pin',
      },
      {
        title: 'Кассы',
        key: '__terminal',
        url: '/terminal',
        icon: 'fe fe-airplay',
      },
    ]
  },
  {
    title: 'Заведения',
    key: '__establish',
    icon: 'icmn-tree',
    roles: ['support'],
    children: [
      {
        title: 'Бренды',
        key: '__brand',
        url: '/brand',
        icon: 'fa fa-copyright',
      },
      {
        title: 'Филиалы',
        key: '__store',
        url: '/store',
        icon: 'fe fe-map-pin',
      },
    ]
  },
  {
    title: 'Заявки',
    key: '__claim',
    url: '/claim',
    icon: 'fe fe-file-text',
    roles: ['admin'],
  },
  {
    title: 'Филиалы',
    url: '/store',
    key: '__store',
    icon: 'fe fe-map-pin',
    roles: ['manager', 'dealer'],
  },
  {
    title: 'Меню',
    key: '__menu',
    icon: 'fe fe-clipboard',
    roles: ['admin', 'brandmanager'],
    children: [
      {
        title: 'Категории',
        key: '__categories',
        name: 'categories',
        url: '/categories/null',
        icon: 'lnr lnr-tag',
        weight: 'bold'
      },
      {
        title: 'Продукты',
        key: '__products',
        name: 'products',
        url: '/products/null',
        icon: 'lnr lnr-dinner',
        weight: 'bold'
      },
      {
        title: 'Залы',
        key: '__hall',
        name: 'hall',
        url: '/hall/null',
        icon: 'lnr lnr-enter',
        weight: 'bold'
      },
      {
        title: 'Столы',
        key: '__tables',
        name: 'tables',
        url: '/tables/null',
        icon: 'lnr lnr-funnel',
        weight: 'bold'
      },
      {
        title: 'Заказы',
        key: '__orders',
        name: 'orders',
        url: '/orders/null',
        icon: 'fe fe-shopping-cart',
      },
    ]
  },
  {
    title: 'Сотрудники',
    key: '__employee',
    url: '/employee',
    icon: 'fe fe-users',
    roles: ['admin', 'support', 'dealer']
  },
  {
    title: 'Сотрудники и чаевые',
    roles: ['manager', 'brandmanager'],
    key: '__employee',
    icon: 'fe fe-users',
    children: [
      {
        title: 'Сотрудники',
        key: '__employee',
        url: '/employee',
        icon: 'fe fe-users',
      },
      {
        title: 'Вывод чаевых',
        key: '__withdraw',
        url: '/withdraw',
        icon: 'fe fe-arrow-up-circle',
      },
      {
        title: 'Полученные чаевые',
        key: '__income',
        url: '/income',
        icon: 'fe fe-arrow-down-circle',
      },
    ]
  },
  {
    title: 'Работа с отзывами',
    key: '__review',
    icon: 'fe fe-edit-3',
    roles: ['admin', 'support', 'dealer', 'manager', 'brandmanager'],
    children: [
      {
        title: 'Все',
        key: '__review_all',
        url: '/report/review/all',
        icon: 'fe fe-minus',
      },
      {
        title: 'Новые',
        key: '__review_new',
        url: '/report/review/new',
        icon: 'fe fe-minus',
      },
      {
        title: 'В обработке',
        key: '__review_progress',
        url: '/report/review/progress',
        icon: 'fe fe-minus',
      },
      {
        title: 'Закрытые',
        key: '__review_closed',
        url: '/report/review/closed',
        icon: 'fe fe-minus',
      },
    ],
  },
  {
    title: 'Платежи',
    key: '__report',
    url: '/report',
    roles: ['admin', 'manager', 'brandmanager', 'cashier', 'support'],
    icon: 'fe fe-dollar-sign',
  },
  // {
  //   title: 'Платежи',
  //   key: '__payments',
  //   url: '/payments',
  //   icon: 'fe fe-dollar-sign',
  //   roles: ['admin'],
  // },
  {
    title: 'Справочники',
    key: '__references',
    icon: 'fe fe-book-open',
    roles: ['admin'],
    children: [
      {
        title: 'Параметры филиалов',
        key: '__store_ref',
        children: [
          {
            title: 'Кухня и блюда',
            key: '__cuisine',
            url: '/references/cuisine',
          },
          {
            title: 'Типы заведений',
            key: '__classification',
            url: '/references/classification',
          },
          {
            title: 'Дополнительные',
            key: '__additional',
            url: '/references/additional',
          },
        ],
      },
      {
        title: 'Модификации',
        key: '__modifiers',
        children: [
          {
            title: 'Группы',
            key: '__modifier_groups',
            url: '/references/modifier/group',
          },
          {
            title: 'Модификации',
            key: '__modifiers',
            url: '/references/modifier',
          },
        ],
      },
      {
        title: 'Ед. измерения',
        key: '__unit',
        url: '/references/unit',
        icon: 'fe fe-flag',
      },
      {
        title: 'Стикеры',
        key: '__sticker',
        url: '/references/sticker',
        icon: 'fe fe-image',
      },
      {
        title: 'Языковые настройки',
        key: '__language',
        url: '/references/language',
        icon: 'fe fe-globe',
      },
      {
        title: 'Роли сотрудников',
        key: '__employee_roles',
        url: '/employee/roles',
        icon: 'fe fe-users',
      },
      {
        title: 'Типы кассовых систем',
        key: '__pos_types',
        url: '/pos',
        icon: 'fe fe-printer',
      },
    ],
  },
  {
    title: 'Бренды',
    key: '__brand',
    url: '/brand',
    roles: ['engineer'],
    icon: 'fa fa-copyright',
  },
  {
    title: 'Филиалы',
    key: '__store',
    url: '/store',
    roles: ['engineer'],
    icon: 'fe fe-map-pin',
  },
  {
    title: 'Кассы',
    key: '__terminal',
    url: '/terminal',
    roles: ['engineer'],
    icon: 'fe fe-airplay',
  },
  {
    title: 'Рассылка',
    key: '__notification',
    url: '/notification',
    roles: ['admin'],
    icon: 'fe fe-mail',
  },
  {
    title: 'Пользователи',
    key: '__users',
    url: '/users',
    icon: 'fe fe-users',
    roles: ['brandmanager'],
  },
  {
    title: 'Настройки',
    key: '__settings',
    icon: 'fe fe-settings',
    roles: ['admin'],
    children: [
      {
        title: 'Пользователи',
        key: '__users',
        url: '/users',
        icon: 'fe fe-users',
      },
      {
        title: 'Токены',
        key: '__tokens',
        url: '/tokens',
        icon: 'fe fe-lock',
      },
      // {
      //   title: 'Архив сотрудников',
      //   key: '__employee_archived',
      //   url: '/employee/archived',
      //   icon: 'fe fe-users',
      // },
      // {
      //   title: 'Фискальный чек',
      //   key: '__tax_receipt',
      //   url: '/receipt',
      //   icon: 'fe fe-check-square',
      // },
      {
        title: 'Платежные системы',
        key: '__payment_systems',
        url: '/payment-systems',
        icon: 'fe fe-dollar-sign',
        roles: ['admin'],
      },
      {
        title: 'Документация',
        key: '__documentations',
        url: '/documentations',
        icon: 'fe fe-paperclip',
        roles: ['admin'],
      },
      // {
      //   title: 'Click merchant',
      //   key: '__click_merchant',
      //   url: '/click-merchant',
      //   icon: 'fe fe-users',
      // },
    ],
  },
  // VB:REPLACE-END:MENU-CONFIG
]
